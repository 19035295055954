<template>
  <nav class="navbar">
    <a href="" class="logo"> <img src="../assets/logov3.png" alt=""></a>
    <ul class="nav-menu">
      <li class="nav-item">
        <router-link to="/" class="nav-link">home</router-link>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link">About</a>
      </li>

      <li class="nav-item">
        <router-link to="/work" class="nav-link">work</router-link>
      </li>

      <li class="nav-item">
        <router-link to="/contact" class="nav-link">Contact</router-link>
      </li> 
    
     


    </ul>
    <div class="hamburger">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
  </nav>
</template>

<script>
export default {

}
</script>

<style>

#contactbutton{
  background-color: white;
  color: black;
  border: 2px solid white;
  padding: 5px 15px; /* Adjust padding as needed */
            text-align: center;
            border-radius: 10%;
}

  #icone{
    width: 18px;
    height: 18px;
  }
  .navbar{
    font-size: 18px;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo{
    margin-left: 15%;
    width: 200px;
  }
  .logo img{
    width: 100%;
    height: 100%;
    margin-top: 10%;
  }
  .nav-menu{
    display: flex;
    align-items: center;
  }
  .nav-branding{
    font-size: 2rem;
  }
  /* transition for links */
  .nav-menu .nav-item{
    list-style: none;
  }
 .nav-menu .nav-item .nav-link{
    transition: 0.5s ease;
    text-decoration: none;
    list-style: none;
    color: white;
  }
  .nav-menu .nav-item .nav-link:hover{
    background: linear-gradient(to left ,#03ffaf, #00bcf2 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .nav-menu .nav-item .nav-link::after{
    content: '';
    width: 0;
    height: 3px;
    background: linear-gradient(to left ,#03ffaf, #00bcf2 );
    margin: auto;
    display: block;
  }
  .nav-menu .nav-item .nav-link:hover::after{
    width: 100%;
    transition: 0.3s linear;
    margin-top:10px;

  }
  .nav-menu .nav-item .router-link-exact-active {
    background: linear-gradient(to left ,#03ffaf, #00bcf2 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    
  
}

  .hamburger{
    display: none;
    cursor: pointer;
  }
  .bar{
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: white;
  }
  .nav-item{
    padding: 0 1.5rem;
  }

  .titre{
    
    justify-content: center;
    
  }

  @media(max-width: 760px){
    .bar{
      display: block;
      
    }
    .logo{
    margin-left: 10%; 
    width: 40%; 
    }
    .hamburger{
      display: block;
      margin-right: 5%;
    }
    .hamburger.active .bar:nth-child(2){
      opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
      transform: rotate(45deg) translateY(11.5px); 
    }  
    .hamburger.active .bar:nth-child(3){
      transform: rotate(-45deg) translateY(-11.5px); 
    }
    .nav-menu{
      position: fixed;
      right: -200%;
      top: 70px;
      flex-direction: column;
      width: 100%;
      text-align: center;
  background:  #030d41 ;
      box-shadow: 0 26px 42px rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }
    .nav-item{
      padding: 1.5rem 0;
    }
    
    .nav-menu.active{
      right: 0;
    }}
    
</style>
