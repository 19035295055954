<template>
  <div class="home">
    <div class="myinfos">
    <div class="textinfos">
    <h1>Welcome To <span class="bigti"> CraftyTechs </span></h1>
    <h2>Transforming Tech Challenges into Solutions</h2>
    <hr>
    <p>Join us in embracing innovation and turning obstacles into opportunities.<br> Together, let's transform your tech challenges into a brighter, more efficient future</p>
    <button class="button" @click="$router.push('/contact')">Contact Us</button>
  </div>
    <img src="../assets/me.png" id="imginfos">
    </div>
    <AppAbout/>
    <AppServicesVue/>
    <AppFooter/>
    </div>
    
</template>

<script>
// @ is an alias to /src

import AppServicesVue from '../components/AppServices.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppAbout from '@/components/AppAbout.vue';

export default {
  name: 'HomeView',
  components: {
    AppAbout,
    AppServicesVue,
    AppFooter,
  }}

</script>
<style>
@import url(https://fonts.googleapis.com/css?family=Poppins);
.home{
  color: white;
  
  
}
.myinfos{
  display: flex;
  justify-content: center;
}

#imginfos img {
  width: 30%;
}

img{
  width: 20%;
  
}
.textinfos{
  padding:60px ;
  text-align: left;

}
h1 .bigti{
  background: linear-gradient(to left ,#03ffaf, #00bcf2 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

p{
font-size: 1rem;
opacity: 0.5;
}
.button{
  margin-top: 5%;
  padding: 10px 30px;
    display: flex;
    border: none;
    border-radius: 15px;
    outline: none;
    font-size: 1em;
    color: #030d41;
    cursor: pointer;
}
hr{
  background-color: aliceblue;
}
@media(max-width: 760px){
  .myinfos{
    display: flex;
    flex-direction: column;
  justify-content: center;
  }
  img{
    width: 30%;
  }
  #imginfos img{
    display: none;
  }
}

</style>