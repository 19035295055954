<template>
  <div id="app">
    <AppBar/>
    <router-view/>
   
  </div>
</template>

<script>
import AppBar from './components/AppBar.vue';



export default {
  name: 'App',
components:{

  AppBar,
  
  }}
</script>




<style>
@import url(https://fonts.googleapis.com/css?family=Poppins);
#app {
  background: linear-gradient(to top right, #042360 , #030d41 );
  margin: 0;
  padding: 0;
  width:100%;
  height: 100%;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.nav-menu .nav-item .nav-link-exact-active {
  color: #00bcf2;
  text-decoration: none;
}
@media(max-width: 760px){
  #app{
  margin: 0;
  padding: 0;
  width:100%;
  }
}
</style>
